<!-- eslint-disable vue/valid-template-root -->
<template></template>
<script>
import { postReq, loadScheduleOptions } from '@/api/schedule';
import { getDdlLabel, extractOperator } from '@/utils/util';

const columns = [];
export default {
  data() {
    return {
      getDdlLabel,
      extractOperator,
      scheduleOptions: {
        subject: [],
        subjectType: [],
        courseType: [],
        standardType: [],
        feeType: [],
        classType: [],
        classStatus: [],
        scheduleStatus: [],
        stuScheduleStatus: [],
        stuScheduleStatusReason: [],
        timeSplitItemStatus: [],
        courseStructure: [],
        formClassCapacityStatus: [],
        workStatus: [],
        leaveStatus: [],
        attendanceStatus: [],
        workType: [],
        cuTransType: [],
        approveStatus: [],
        taskLogType: [],
        userRole: [],
        lossRefundType: [],
        lossRefundReason: {
          lossReasons: {},
          notRenewReasons: [],
          otherLossReasons: [],
          refundReasons: [],
        },
        shelfStatus: [],
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      data: [],
      columns,
      loading: false,
      pagination: {
        pageSize: 20,
        current: 1,
        total: 0,
      },
      searchUrl: '',
      form: {},
      urlContainsSearch: true,
      queryParam: {},
    };
  },
  created() {
    // 判断是不是从学生详情页过来
    if (this.$route.query.studentId) {
      this.queryParam.studentId = this.$route.query.studentId;
    }
    if (this.$route.query.showAll) {
      this.queryParam.showAll = true;
    }
    this.getListData();

    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });
  },
  methods: {
    handleTableChange(pagination) {
      this.getListData(pagination.current);
    },
    getListData(currentPage) {
      this.loading = true;
      postReq(
        `${this.searchUrl}${this.urlContainsSearch ? '/search' : ''}/${currentPage || this.pagination.current}${
          this.urlContainsSearch ? `?studentTimezone=${this.$route.query.studentTimezone}` : ''
        }`,
        {
          ...this.form,
          ...this.queryParam,
        },
      )
        .then((res) => {
          this.data = res.data.content;
          this.pagination.pageSize = res.data.pageSize;
          this.pagination.total = res.data.totalCount;
          this.pagination.current = res.data.currentPage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    resetForm(e) {
      e.preventDefault();
      this.traverseReset(this.form);
    },
    traverseReset(form) {
      // eslint-disable-next-line no-restricted-syntax
      for (const i in form) {
        if (!!form[i] && Object.prototype.toString.call(form[i]) === '[object Object]') {
          this.traverseReset(form[i]);
        } else {
          // eslint-disable-next-line no-param-reassign
          form[i] = undefined;
        }
      }
    },
  },
};
</script>
