<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="学生">
              <a-input allow-clear v-model="form.name" placeholder="姓名或学号" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="国家/地区">
              <a-select
                show-search
                allowClear
                :filter-option="filterOption"
                option-filter-prop="children"
                v-model="form.country"
                placeholder="请选择"
              >
                <a-select-option v-for="(item, index) in optionsArr.country" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="班型">
              <a-select v-model="form.classType" allowClear placeholder="请选择">
                <a-select-option v-for="item in scheduleOptions.classType" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="科目">
              <a-select allow-clear v-model="form.subject" placeholder="请选择">
                <a-select-option v-for="item in subjectArr" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="已扣课时">
              <a-input-number
                style="width: 40%"
                v-model="form.consumedCourseUnitStart"
                placeholder="请输入"
                allowClear
              />
              <span class="divider-line">-</span>
              <a-input-number style="width: 40%" v-model="form.consumedCourseUnitEnd" placeholder="请输入" allowClear />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="流失类型">
              <a-select allow-clear v-model="form.lossRefundType" placeholder="请选择">
                <a-select-option v-for="item in scheduleOptions.lossRefundType" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="流失状态">
              <a-select allow-clear v-model="form.completionStatus" placeholder="请选择">
                <a-select-option v-for="item in completionStatusArr" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :span="6"
            v-if="
              userInfo.roleArr.includes('TEACHING_STAFF') ||
              userInfo.roleArr.includes('TEACHING_STAFF_ADMIN') ||
              userInfo.roleArr.includes('HEAD_TEACHER_SUPER')
            "
          >
            <a-form-item label="组织(部门)">
              <a-tree-select
                allow-clear
                v-model="form.departmentId"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="treeData"
                placeholder="请选择"
                tree-default-expand-all
              >
              </a-tree-select>
            </a-form-item>
          </a-col>
          <a-col
            :span="6"
            v-if="
              userInfo.roleArr.includes('TEACHING_STAFF') ||
              userInfo.roleArr.includes('TEACHING_STAFF_ADMIN') ||
              userInfo.roleArr.includes('HEAD_TEACHER_SUPER')
            "
          >
            <a-form-item label="流失前班主任">
              <a-select
                show-search
                @search="searchClassAdmin"
                v-model="form.classAdmin"
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请选择"
                allow-clear
              >
                <a-select-option :value="i.uuid" v-for="i of classAdminList" :key="i.uuid">
                  {{ i.fullName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
              label="现班主任"
              v-if="
                userInfo.roleArr.includes('TEACHING_STAFF') ||
                userInfo.roleArr.includes('TEACHING_STAFF_ADMIN') ||
                userInfo.roleArr.includes('HEAD_TEACHER_SUPER')
              "
            >
              <a-select
                show-search
                @search="searchClassAdmin"
                v-model="form.currentClassAdmin"
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请选择"
                allow-clear
              >
                <a-select-option :value="i.uuid" v-for="i of classAdminList" :key="i.uuid">
                  {{ i.fullName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="创建时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-range-picker @change="onChange" v-model="form.dateRange" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="流失确定时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-range-picker @change="onChangeLose" v-model="form.completeDateRange" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button style="margin-right: 10px" @click="getListData(1)"> 查询 </a-button>
              <a-button style="margin-right: 10px" @click="resetForm"> 重设 </a-button>
              <a-button style="margin-right: 10px" type="primary"
                ><router-link :to="{ path: '/lostRefundEdit' }"> 新建 </router-link></a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- <a-row :gutter="24">
      <a-form-model :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" class="ant-advanced-search-form">
        <a-col :span="6">
          <a-form-model-item label="学生姓名">
            <a-input allow-clear v-model="form.studentName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="学 号">
            <a-input allow-clear v-model="form.studentCode" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="流失类型">
            <a-select allow-clear v-model="form.lossRefundType" placeholder="请选择">
              <a-select-option v-for="item in scheduleOptions.lossRefundType" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="流失状态">
            <a-select allow-clear v-model="form.completionStatus" placeholder="请选择">
              <a-select-option v-for="item in completionStatusArr" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="创建时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker @change="onChange" v-model="form.dateRange" style="width:100%"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item>
            <a-button style="margin-right: 10px" @click="getListData(1)"> 查询 </a-button>
            <a-button style="margin-right: 10px" @click="resetForm"> 重设 </a-button>
            <a-button style="margin-right: 10px" type="primary"><router-link :to="{ path: '/lostRefundEdit'}"> 新建 </router-link></a-button>
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </a-row> -->
    <div class="table-wrap">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <span slot="subject" slot-scope="text">{{ getDdlLabel(scheduleOptions.subject, text) }}</span>
        <span slot="studentCode" slot-scope="studentCode, record">
          <a @click="toStudentDetail(record)">{{ studentCode }}</a>
        </span>
        <span slot="lossRefundType" slot-scope="text">{{ getDdlLabel(scheduleOptions.lossRefundType, text) }}</span>
        <span slot="completionStatus" slot-scope="text">{{ getDdlLabel(completionStatusArr, text) }}</span>

        <span slot="whoCreated" slot-scope="text">{{ extractOperator(text) }}</span>
        <span slot="action" slot-scope="text, record" style="word-break: keep-all">
          <router-link :to="{ path: '/lostRefundDetail', query: { id: record.uuid } }"> 查看 </router-link>
          <router-link
            v-if="record.completionStatus !== 'COMPLETED'"
            :to="{ path: '/lostRefundEdit', query: { id: record.uuid } }"
          >
            编辑
          </router-link>
          <a
            @click="deleteItem(record.uuid, record.studentRespDTO.fullName)"
            v-if="
              record.completionStatus !== 'COMPLETED' &&
              (userInfo.roleArr.includes('TEACHING_STAFF') || userInfo.roleArr.includes('TEACHING_STAFF_ADMIN'))
            "
          >
            删除
          </a>
          <a
            @click="finishTask(record)"
            v-if="
              record.completionStatus !== 'COMPLETED' &&
              (userInfo.roleArr.includes('TEACHING_STAFF') || userInfo.roleArr.includes('TEACHING_STAFF_ADMIN'))
            "
            >完成</a
          >
        </span>
      </a-table>
    </div>

    <a-modal
      title="完成"
      :visible="visibleResult"
      @ok="submitInfo"
      @cancel="cancelResult"
      okText="确定完成"
      cancelText="取消"
    >
      <div class="trans-wrap">
        <div class="trans-title">
          注意学员{{ getDdlLabel(scheduleOptions.subject, recordObj.subject) }}当前还存在课时/额度
        </div>
        <div class="trans-itemWrap">
          <div class="trans-item" v-if="courseData['PURCHASED']">
            <span>购买课时：</span><span>{{ courseData['PURCHASED'].remainingCu }}</span>
          </div>
          <div class="trans-item" v-if="courseData['GIFT_PURCHASED']">
            <span>购买赠课：</span><span>{{ courseData['GIFT_PURCHASED'].remainingCu }}</span>
          </div>
          <div class="trans-item" v-if="courseData['GIFT_DISTRIBUTION']">
            <span>分销赠课：</span><span>{{ courseData['GIFT_DISTRIBUTION'].remainingCu }}</span>
          </div>
          <div class="trans-item" v-if="courseData['GIFT_RECOMMEND']">
            <span>推荐赠课：</span><span>{{ courseData['GIFT_RECOMMEND'].remainingCu }}</span>
          </div>
          <div class="trans-item" v-if="courseData['GIFT_ADJUST']">
            <span>手动变更课时：</span><span>{{ courseData['GIFT_ADJUST'].remainingCu }}</span>
          </div>

          <div class="trans-item" v-if="courseData['GIFT_HOMEWORK_COMPLETION']">
            <span>伴学奖赠课：</span><span>{{ courseData['GIFT_HOMEWORK_COMPLETION'].remainingCu }}</span>
          </div>
          <div class="trans-item" v-if="courseData['GIFT_SOCIAL_NETWORK_SHARE']">
            <span>社交平台分享赠课：</span><span>{{ courseData['GIFT_SOCIAL_NETWORK_SHARE'].remainingCu }}</span>
          </div>
          <div class="trans-item" v-if="courseData['GIFT_SIGNUP_SHARE']">
            <span>入学分享赠课：</span><span>{{ courseData['GIFT_SIGNUP_SHARE'].remainingCu }}</span>
          </div>
          <div class="trans-item" v-if="courseData['GIFT_SMALL_LESSON']">
            <span>小班课专属赠课：</span><span>{{ courseData['GIFT_SMALL_LESSON'].remainingCu }}</span>
          </div>
          <div class="trans-item" v-if="courseData['GIFT_COMPENSATE']">
            <span>课时补偿/赠课：</span><span>{{ courseData['GIFT_COMPENSATE'].remainingCu }}</span>
          </div>

          <div class="trans-item" v-if="courseData['GIFT_SOCIAL_NETWORK_SHARE']">
            <span>分享赠课额度：</span><span>{{ courseData['GIFT_SOCIAL_NETWORK_SHARE'].remainingQuota }}</span>
          </div>
        </div>
      </div>
      <div class="warn-txt">点击“完成”后，以上学员科目下的课时/额度将变为0！</div>
    </a-modal>
  </div>
</template>
<script>
import storage from 'store';
import { postReq, putReq, deleteReq, loadTimezoneList, loadClassAdminOptions } from '@/api/schedule';
import { getDdlLabel } from '@/utils/util';
import BaseList from '@/components/BaseList/index.vue';
import { finishLoseRefund, getTreeList, selClassAdmin, queryScreenOption } from '@/api/headTeacher';

const columns = [
  {
    title: '学生姓名',
    dataIndex: 'studentRespDTO.fullName',
    key: 'studentRespDTO.fullName',
  },
  {
    title: '学 号',
    dataIndex: 'studentRespDTO.code',
    key: 'studentRespDTO.code',
    scopedSlots: { customRender: 'studentCode' },
  },
  {
    title: '科 目',
    dataIndex: 'subject',
    key: 'subject',
    scopedSlots: { customRender: 'subject' },
  },
  {
    title: '流失类型',
    key: 'lossRefundType',
    dataIndex: 'lossRefundType',
    scopedSlots: { customRender: 'lossRefundType' },
  },
  {
    title: '流失状态',
    key: 'completionStatus',
    dataIndex: 'completionStatus',
    scopedSlots: { customRender: 'completionStatus' },
  },
  {
    title: '流失前班主任',
    dataIndex: 'classAdminData.fullName',
    key: 'fullName',
  },
  {
    title: '创建人',
    key: 'whoCreated',
    dataIndex: 'whoCreated',
    scopedSlots: { customRender: 'whoCreated' },
  },
  {
    title: '创建时间',
    key: 'whenCreated',
    dataIndex: 'whenCreated',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  extends: BaseList,
  data() {
    return {
      roleFLag: '',
      userInfo: {},
      optionsArr: {},
      classAdminList: [],
      treeData: [],
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
         { label: '英文', value: 'ENGLISH' },
      ],
      courseData: {},
      recordObj: {},
      completionStatusArr: [
        { label: '未处理', value: 'NORMAL' },
        { label: '已完成', value: 'COMPLETED' },
      ],
      visibleResult: false,
      getDdlLabel,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      columns,
      searchUrl: `/api/admin/scheduling/loss_refund`,
      form: {
        completionStatus: undefined,
        lossRefundType: undefined,
        name: undefined,
        classType: undefined,
        startDateTime: null,
        endDateTime: null,
        dateRange: [],
        subject: undefined,
        consumedCourseUnitEnd: undefined,
        consumedCourseUnitStart: undefined,
        currentClassAdmin: undefined,
        whenCompleteStartDateTime: undefined,
        whenCompleteEndDateTime: undefined,
        completeDateRange: [],
      },
    };
  },
  created() {
    const obj = storage.get('userInfo');
    this.userInfo = obj;
    // 判断按钮角色
    this.roleFLag = this.userInfo.roleArr.find((role) => role === 'CLASS_ADMIN');

    this.getTreeList();
    this.queryScreenOption();
  },
  methods: {
    // 获取下来项
    queryScreenOption() {
      queryScreenOption().then((res) => {
        this.optionsArr = res.data.content;
      });
    },
    toStudentDetail(record) {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: {
          uuid: record.studentId,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    searchClassAdmin(name) {
      const params = {
        name,
      };
      selClassAdmin(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
      });
    },
    finishTask(record) {
      this.recordObj = record;

      this.finishLoseRefund(true);
    },
    submitInfo() {
      // 点击弹窗里面确定完成
      this.finishLoseRefund(false);
    },
    finishLoseRefund(val) {
      const params = {
        lossRefundId: this.recordObj.uuid,
        validate: val,
      };
      finishLoseRefund(params).then((res) => {
        const data = res.data.content;
        if (data !== null) {
          this.courseData = data;
          this.visibleResult = true;
        } else {
          this.visibleResult = false;
          this.$message.success('流失状态为已完成');
          this.getListData(1);
        }
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    cancelResult() {
      this.visibleResult = false;
    },
    onChange(date) {
      if (date.length > 0) {
        this.form.startDateTime = date[0].format('YYYY-MM-DD HH:mm:ss');
        this.form.endDateTime = date[1].format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.form.startDateTime = null;
        this.form.endDateTime = null;
      }
    },
    onChangeLose(date) {
      if (date.length > 0) {
        this.form.whenCompleteStartDateTime = date[0].format('YYYY-MM-DD HH:mm:ss');
        this.form.whenCompleteEndDateTime = date[1].format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.form.whenCompleteStartDateTime = null;
        this.form.whenCompleteEndDateTime = null;
      }
    },
    deleteItem(id, name) {
      const _this = this;
      this.$confirmAnt({
        title: '删除学员流失记录',
        content: `确认删除学员流失记录 ${name} 吗?`,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          _this.loading = true;
          deleteReq(`${_this.searchUrl}/${id}`, {})
            .then((res) => {
              _this.getListData();
            })
            .finally(() => {
              _this.loading = false;
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0px;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);

  padding: 10px 20px;
}
.trans-itemWrap {
  display: flex;
  flex-wrap: wrap;
}
.trans-item {
  width: 50%;
  margin-bottom: 10px;
}
.trans-title {
  font-size: 14px;
  margin-bottom: 20px;
}
.warn-txt {
  color: red;
  margin-top: 20px;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.divider-line {
  padding: 0 12px;
}
</style>
